/* 
Empty check for null, undefined, object, array, string, number and date
*/
export function isEmpty(val) {
  if (val === null || val === undefined || typeof val === "number") {
    return !!!val;
  }
  if (val instanceof Date && !isNaN(val)) {
    return !!!val;
  }
  if (Array.isArray(val)) {
    return !!!val.length;
  }
  if (typeof val === "object") {
    return Object.keys(val).length === 0;
  }
  if (typeof val === "string") {
    return !val.trim();
  }
  throw new Error("unknown type");
}

/* DateFunctions */
const dayConversion = 60 * 60 * 24 * 1000;

const formatOptions = { year: "numeric", month: "2-digit", day: "2-digit" };

export function todayDate() {
  return new Date(new Date().getTime()).toLocaleDateString(
    "en-US",
    formatOptions
  );
}

export function nextDayDate(dateStr) {
  const nextDate = new Date(dateStr);
  nextDate.setDate(nextDate.getDate() + 1);
  return nextDate.toLocaleDateString("en-US", formatOptions);
}

export function yesterdayDate() {
  return new Date(new Date().getTime() - dayConversion).toLocaleDateString(
    "en-US",
    formatOptions
  );
}

export function oneWeekAgoDate() {
  return new Date(new Date().getTime() - dayConversion * 7).toLocaleDateString(
    "en-US",
    formatOptions
  );
}

export function twoWeeksAgoDate() {
  return new Date(new Date().getTime() - dayConversion * 14).toLocaleDateString(
    "en-US",
    formatOptions
  );
}

// Provides locale date in yyyy-mm-dd format
export function todayLocaleDate() {
  return new Date().toLocaleDateString("en-CA");
}

export function oneYearFromNowLocaleDate() {
  return new Date(
    new Date().getTime() + dayConversion * 365
  ).toLocaleDateString("en-CA");
}

// Provides local-to-utc date-string with timestamp
export function localToUtcDateWithTimeStamp(dateStr, timestamp) {
  if (isEmpty(dateStr) || isEmpty(timestamp)) {
    return null;
  }
  return new Date(`${dateStr} ${timestamp}`).toISOString();
}

// Provides utc-to-local date-string in mm-dd-yyyy format
export function utcToLocalmmddyyyyString(date) {
  if (isEmpty(date)) {
    return null;
  }
  const [month, day, year] = new Date(date)
    .toLocaleDateString("en-US", formatOptions)
    .split("/");
  return `${month}-${day}-${year}`;
}

// Provides utc-to-local date-string current region system format
export function utcToLocalString(date) {
  if (isEmpty(date)) {
    return null;
  }
  const localFormattedDate = new Date(date).toLocaleDateString(
    undefined,
    formatOptions
  );
  return localFormattedDate;
}

// Provides date-string in yyyy-mm-dd to mm/dd/yyyy format
export function yyyymmddTommddyyyyString(date) {
  if (isEmpty(date)) {
    return "";
  } else if (date.includes("-")) {
    const [year, month, day] = date.split("-");
    return `${month}/${day}/${year}`;
  } else {
    return date;
  }
}

// Provides date-string in mm/dd/yyyy to yyyy-mm-dd format
export function mmddyyyyToyyyymmddString(date) {
  if (isEmpty(date)) {
    return "";
  } else if (date.includes("/")) {
    const [month, day, year] = date.split("/");
    return `${year}-${month}-${day}`;
  } else {
    return date;
  }
}

/* Requisitions */
export const getAccessorNames = (accessors, delimiter) => {
  const lastIndex = accessors.length - 1;
  const accessorNames = accessors.reduce(
    (previousNames, { name, email, groupName }, index) => {
      const nameOrEmail = email || groupName || name;
      let nameList = !isEmpty(nameOrEmail)
        ? `${previousNames}${nameOrEmail}`
        : previousNames;
      return index !== lastIndex ? `${nameList}${delimiter}` : nameList;
    },
    ""
  );
  return accessorNames;
};

export const getSelectedGroupsAndUsers = (accessors, accessGroups) => {
  let users = [];
  let groups = [];
  accessors.forEach((contact) => {
    let targetContact = accessGroups.find(
      (item) => item.handle === contact.handle
    );
    if (targetContact?.groupName) {
      groups.push({ handle: contact.handle });
    } else if (targetContact?.email) {
      users.push({ handle: contact.handle });
    }
  });

  return [groups, users];
};

/* Capabilities */
export const findAuthorizedFeature = (featuresList = [], featureName) => {
  const authorizedFeature = featuresList.find(
    ({ feature }) => feature === featureName
  );
  return authorizedFeature;
};

export const isCapabilityAllowed = (capabilityObj = {}, capabilities = []) => {
  const capabilityFound = capabilities.find(
    (capability) =>
      capability?.api === capabilityObj?.api &&
      capability?.accessLevel === capabilityObj?.accessLevel
  );
  return capabilityFound !== undefined;
};

/* Miscellaneous */
export const reloadPage = () => window.location.reload(false);
