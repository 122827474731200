import { useEffect, useState } from "react";
import { ReactComponent as IconCart } from "../../Assets/Images/icon-cart.svg";
import { GET } from "../../Services/io";
import { subscribe, unsubscribe } from "../../Services/pubSub";
import { useAuthContext } from "../Hooks/useAuthContext";
import { API_PATHS } from "../Utilities/constants";

const { REQUISITION_MY } = API_PATHS;

const CartIcon = () => {
  const [itemCount, setItemCount] = useState();

  // Collecting authentication context
  const { dpodUser, authParams } = useAuthContext();

  useEffect(() => {
    const handleCountChange = (newValue) => {
      setItemCount(newValue);
    };
    // Subscribe to the "countChange" event
    subscribe("countChange", handleCountChange);

    // Clean up subscription on component unmount
    return () => {
      unsubscribe("countChange", handleCountChange);
    };
  }, []);

  useEffect(() => {
    const getCartData = async () => {
      const response = await GET(REQUISITION_MY, authParams);
      if (response && !response?.ErrorList) {
        setItemCount(response.data.itemCount);
      }
    };

    if (dpodUser) {
      getCartData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dpodUser]);

  return (
    <span
      className="position-relative"
      style={{ width: "1.65em", height: "1.45em" }}
    >
      <IconCart style={{ fontSize: "0.9em" }} />
      {itemCount && (
        <span
          className="cart-counter-bg badge position-absolute top-0 start-100 translate-middle-y rounded-circle"
          style={{ fontSize: ".8em" }}
        >
          <span> {itemCount} </span>
        </span>
      )}
    </span>
  );
};

export default CartIcon;
